<template>
  <div id="app">
    <transition name="fade">
      <Loader v-show="isLoading" />
    </transition>
    <div class="header">
      <a href="http://holosno.com.ua//">
        <img src="@/assets/logo.svg" alt="" height="72" />
      </a>
    </div>
    <div class="main">
      <div
        class="poster"
        v-if="!no_data"
        :style="{ height: posterHeight() + 'px' }"
        :key="key"
        v-html="poster ? poster : 'Loading'"
      ></div>
      <div
        class="no_data"
        v-if="no_data"
        :style="{ height: posterHeight() + 'px' }"
        :key="key"
      >
        <h1 style="margin-bottom: 12px !important">Подаруй свій голос</h1>
        <p style="margin-top: 0px !important; font-size: 16px">
          Важливі слова - найкращий подарунок для особливої людини Скажи їх
          голосно!
        </p>
      </div>
      <div class="footer" id="footer">
        <div class="footer__inner">
          <div class="controls">
            <div class="audio" id="sound"></div>

            <div class="cta">
              <a href="http://holosno.com.ua/" v-if="!no_data"
                >Створити новий</a
              >
              <a href="http://holosno.com.ua/" v-if="no_data"
                >Створити повідомлення</a
              >
            </div>
            <div class="links"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/loader.vue";
//const decode = require("audio-decode");
//const buffer = require("audio-lena/mp3");
// var AV = require("av");
// require("vorbis.js");
var ogv = require("ogv");
export default {
  data() {
    return {
      isLoading: true,
      api_url:
        "https://europe-west3-holosno-com-ua.cloudfunctions.net/api/getPosterForLanding",
      uid: null,
      poster: null,
      audio: null,
      player_index: 1,
      no_data: false,
      done: false,
      key: 1,
    };
  },
  computed: {},
  components: {
    Loader,
  },
  methods: {
    onImgLoad: function () {
      this.isLoading = false;
      this.posterHeight();
    },
    playAudio(url) {
      // var request;

      // Create a new player with the constructor

      // Or with options
      var player = new ogv.OGVPlayer({
        debug: true,
        debugFilter: /demuxer/,
      });
      // Now treat it just like a video or audio element
      var containerElement = document.getElementById("oggplayer");
      containerElement.appendChild(player);
      player.src = url;

      player.muted = true;
      player.play();
      player.addEventListener("ended", function () {
        alert("ready");
      });

      // try {
      // 	request = new XMLHttpRequest();
      // 	request.open("GET", url, true);
      // 	request.responseType = "arraybuffer";

      // 	request.onload = function() {
      // 		// console.log(request.response);
      // 		request.response;
      // 	};

      // 	request.send();
      // } catch (e) {
      // 	alert("web audio api not supported");
      // }
    },
    getData(uid) {
      this.dataLoading = true;
      fetch(this.api_url + "?uid=" + uid, {
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })
        .then(async (data) => {
          if (!data.error) {
            fetch(data.preview)
              .then((r) => r.text())
              .then((text) => {
                this.audio = data.sound_url;
                this.sound_url = data.sound_url;
                this.poster = text;

                var sound = document.createElement("audio");
                sound.id = "audio-player";
                sound.controls = "controls";
                sound.src = this.sound_url;
                sound.type = "audio/wav";
                document.getElementById("sound").appendChild(sound);

                this.onImgLoad();
              })
              .catch(console.error.bind(console));
          } else {
            this.isLoading = false;
            this.no_data = true;
            this.$router.push("/");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.no_data = true;
          console.log(error);
        });
    },
    async checkIfData() {
      if (this.$route.params.uid) {
        this.uid = this.$route.params.uid;
        await this.getData(this.uid);
      } else {
        this.no_data = true;
        this.isLoading = false;
      }
    },
    posterHeight: function () {
      var height = "auto";
      if (document.getElementById("footer")) {
        var f_height = document.getElementById("footer").scrollHeight;
        var w_height = document.documentElement.clientHeight;
        height = Number(w_height) - Number(f_height) - 42;
        console.log("fire", height);
      }

      return height;
    },
  },
  // beforeMount() {

  // },
  async beforeMount() {
    await this.checkIfData();
  },
  async mounted() {
    //check if case = edit

    this.posterHeight();
    this.key = Math.random();
    window.addEventListener("resize", () => {
      this.key = Math.random();
      this.posterHeight();
    });

    window.addEventListener("orientationchange", () => {
      this.key = Math.random();
      this.posterHeight();
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/_reset.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;700&display=swap");

body {
  margin: 0px !important;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

#app {
  * {
    outline: none !important;
  }
}

.main {
  padding-top: 72px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-top: 60px;
  }
}

.poster {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  svg {
    max-width: 80%;
    margin: 30px;
    max-height: 100%;
    rect {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    @media (max-width: 768px) {
      max-width: 100%;
      margin: 0px;
    }
  }
}

.audio {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  audio {
    width: 360px;
    max-width: 90%;
  }
}

.main {
  min-height: 100vh;
  display: block;
  // grid-template-rows: auto auto;
  height: 100vh;
  box-sizing: border-box;

  .cta {
    padding: 30px 20px;
    text-align: center;
    margin-bottom: 0px;
    max-width: 100%;
    box-sizing: border-box;
    @media (max-width: 768px) {
      padding: 15px 0px;
    }
    a {
      background: #000000;
      border-radius: 6px;
      height: 48px;
      display: flex;
      width: 360px;
      max-width: 90%;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      color: #ffffff;
      position: relative;
      transition: 0.3s;
      border: none !important;
      margin: 0px auto;
      text-decoration: none;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      &::after {
        transition: 0.6s;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        display: block;
        background: white;
        opacity: 0;
        background: linear-gradient(
          176deg,
          rgba(255, 255, 255, 0.7637429972) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        &:hover {
          transition: 0.3s;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
          background: #000000 !important;
          color: #fff;
        }
      }
    }
  }
}

.no_data {
  background: #f0f0f0;
  padding: 30px;
  color: #424242;
  text-transform: uppercase;
  text-align: center;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
      transition: 0.3s;
    }
  }
}

.footer {
  grid-row: 2;
  background: #fff;

  &__inner {
    min-height: 100%;

    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 20px;

      @media (max-width: 768px) {
        min-height: 100%;
        box-sizing: border-box;
        padding-top: 15px;
      }

      .links {
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 60px;
        margin-top: auto;

        a {
          color: #2f2f2f;
          font-size: 14px;
          text-decoration: none;
          display: inline-block;
          margin: 5px;
          transition: 0.3s;

          @media (max-width: 768px) {
            font-size: 12px;
          }

          &:hover {
            transition: 0.3s;
            color: #d1c5af;
          }
        }
      }
    }
  }

  .stripe {
    background: #d1c5af;
    color: #fff;
    padding: 15px;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.poster {
  svg {
    height: 100%;
  }
}

.header {
  padding: 10px 15px;
  height: 120px;
  @media (max-width: 768px) {
    height: 96px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  a {
    img {
      height: 72px;
      @media (max-width: 768px) {
        height: 42px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
